import Popups from './components/popups';
import Form from './components/form';
import PhoneMask from './components/phoneMask';
import { scrollChecker } from './components/scrollChecker';

document.addEventListener('DOMContentLoaded', () => {
    const popups = new Popups('[data-popup]');
    const forms = new Form({
        trigger: '[type="submit"]',
        callback: (popup) => {
            popups.close(popup);
        }
    });
    new PhoneMask('[name="phone"]');
    scrollChecker('.card', 'visible');

    document.querySelectorAll('a[data-goal]').forEach((a) => {
        a.addEventListener('click', (e) => {
            forms.g_send_goal(a.dataset.goal, a.dataset.goalCategory);
        })
    })
});